<template>
  <div class="share-poster" v-show="show">
    <div @click="close" class="dimmer"></div>
    <transition appear name="slide-up">
      <div class="poster" v-if="isWechat" v-show="show">
        <div class="text-muted mb-2">
          <slot name="desc">长按保存下方海报，分享给好友</slot>
        </div>
        <square :src="poster" height="144%" class="poster-image mx-auto" img/>
        <slot name="append"></slot>
      </div>
    </transition>
    <transition appear name="slide-up">
      <div class="share-qrcode" v-if="!isWechat" v-show="show">
        <p>
          <qrcode :text="`https://weixinshu.com/books/${shareBookId}/share`" size="160px"/>
        </p>
        <p class="text-muted">
          <fa icon="weixin" fw/>
          微信扫码后
          <br>
          点击右上角
          <fa icon="ellipsis-h" fw fas/>
          进行分享
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import Qrcode from './QRCode.vue'

export default {
  name: 'sharePoster',
  props: {
    show: Boolean,
    shareBookId: String,
    book: Object,
    defaults: {
      type: Object,
      default() {
        return {
          codeName: 'cover-6'
        }
      }
    }
  },
  components: {Qrcode},
  data() {
    return {
      posterName: 'zccs2'
    }
  },
  created() {
    if (this.$day().isBetween('2021-12-09', '2021-12-13')) {
      // 定时显示活动海报
      this.posterName = 'double12_2021'
    }
  },
  methods: {
    shareTo(type) {
      this.shareToType = type
    },
    close() {
      this.shareToType = ''
      this.$emit('update:show', false)
    }
  },
  computed: {
    coverUrl() {
      if (this.src) {
        return this.src
      }
      if (this.book) {
        return this.getCoverUrl(this.book)
      }
      return ''
    },
    poster() {
      const shareUrl = 'https://weixinshu.com/book-share/' + this.$route.params.bookId
      const query = {
        cover: this.coverUrl,
        avatar: this.user.avatar,
        author: this.user.nickname,
        url: shareUrl
      }
      return 'https://canvas.xinshu.me/generate/' + this.posterName + this.serialize(query)
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-up-enter-active, .slide-up-leave-active {
  transition: transform .3s;
}

.share-poster {
  .dimmer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 15;
    background-color: rgba(0, 0, 0, .5);
  }

  .poster, .share-qrcode {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 15;
    background: #fff;
    padding: 1em;
  }

  .poster {
    .poster-image {
      max-width: 375px;
      width: 72%;
    }

    @include iPhoneX {
      padding-bottom: $safe-area-bottom;
    }
  }

  .share-qrcode {

  }
}
</style>
