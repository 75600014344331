<template>
  <div class="book-share">
    <loading v-if="loading"/>
    <template v-else>
      <section>
        <book-previewer ref="previewer" :book="book"/>
      </section>

      <footer v-if="!fullscreen && bookMeta && book.bookType !== 'blogbook'">
        <div class="footer-inner">
          <b-row align-v="center">
            <b-col>
              <fa icon="eye"/>
              阅读 {{bookMeta.views || 0}}
            </b-col>
            <b-col cols="auto" style="min-width: 33.3%;">
              <b-btn variant="primary" pill @click="thumbsUp" block v-if="!hasLiked">
                <fa icon="thumbs-up"/>
                赞
              </b-btn>

              <b-btn variant="primary" pill @click="makeBook" block v-else>
                <fa icon="book"/>
                我也做一本
              </b-btn>
            </b-col>

            <b-col class="text-right" @click="showLikes">
              <fa icon="heart" class="text-primary" :fas="hasLiked"/>
              已集赞
              {{bookMeta.totalLikes || 0}}
            </b-col>
          </b-row>
        </div>
      </footer>
    </template>

    <b-modal title="点赞好友" id="likes" hide-footer>
      <empty v-if="!bookMeta.totalLikes" icon="users">本书还没有好友点赞哦</empty>
      <template v-else>
        <div>本书共收到了 {{bookMeta.totalLikes}} 赞，以下为点赞的好友，每位好友 1 年内只能点赞一次哦</div>
        <b-row>
          <b-col cols="6" v-for="item in supporters" :key="item.openid">
            <b-row align-v="center" class="mt-3">
              <b-col cols="auto" class="pr-0">
                <avatar :src="item.headimgurl" bordered size="3em"/>
              </b-col>
              <b-col style="min-width: 0;">
                <h6 class="text-overflow mb-0">{{item.nickname}}</h6>
                <fa icon="thumbs-up"/>
                <template v-if="item.totalLikeCount > 1">{{item.totalLikeCount}}</template>
                <datetime class="text-muted" :value="item.latestLikeTime"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <popup v-if="makeBookAdActive" title="我也要做一本" size="560x640"
           @close="makeBookAdActive = false"
           src="https://img.xinshu.me/upload/qzwyyzyb/1556160549672"/>
  </div>
</template>

<script>
import BookPreviewer from './BookPreviewer'

import routeData from '@/mixins/route-data'
import { configShare } from '@/modules/wechat'

export default {
  name: 'bookShare',
  title: '分享作品',
  mixins: [
    routeData('book', {query: {agent: true}}),
    routeData('bookMeta'),
    require('@/mixins/reload-needed').default,
    require('@/mixins/book-utils').default
  ],
  components: {BookPreviewer},
  data() {
    return {
      supporters: [],

      visited: false,
      liked: false,

      makeBookAdActive: false,
      giftAdActive: false,
      loadError: false
    }
  },
  computed: {
    fullscreen() {
      return !!this.$route.query.fullscreen
    },
    isSharing() {
      return this.bookMeta.isSharing
    },
    isOwner() {
      return this.book.auth === 'edit'
    },
    hasLiked() {
      return this.bookMeta.hasLiked
    }
  },
  methods: {
    async onLoad() {
      if (!this.book.id) {
        this.loadError = true
        return
      }

      const shareContents = {
        wxbook: '我的朋友圈内容做成书啦，进来帮我点赞吧！',
        wbbook: '我的微博内容做成书啦，进来帮我点赞吧！',
        diarybook: '我的回忆做成书啦，进来帮我点赞吧！'
      }

      this.$setTitle(this.book.title)
      configShare({
        title: document.title,
        timelineTitle: shareContents[this.book.bookType],
        link: 'https://weixinshu.com/book-share/' + this.$route.params.bookId,
        desc: shareContents[this.book.bookType],
        imgUrl: this.bookCover
      })

      if (this.isSharing && !this.isOwner) {
        this.visitBook()
      }

      if (this.$route.query.thumb && !this.isOwner && !this.hasLiked) {
        this.thumbsUp()
      }

      if (this.book.bookType === 'blogbook') {
        const catalogPages = await this.$ajax.fetchBlogbookCatalogPages({bid: this.book.id})
        this.book = Object.assign({}, this.book, {catalogPages})
      }
    },
    async showLikes() {
      this.$bvModal.show('likes')
      if (!this.supporters.status) {
        try {
          this.supporters.status = 1
          this.supporters = await this.$ajax.fetchSupporters(this.$route.params)
          this.supporters.status = 2
        } catch (err) {
          this.supporters.status = 0
        }
      }
    },
    async thumbsUp() {
      if (this.hasLiked) {
        return
      }

      if (this.isOwner) {
        this.$alert.error('不能给自己点赞哦，分享给好友集赞吧')
        return
      }

      let url = this.$route.fullPath
      url += (url.includes('?') ? '&' : '?') + 'thumb=1'

      if (!this.hasLogin) {
        if (!this.isWechat) {
          // 浏览器中需要用户确认登录，微信则直接进行授权
          const confirmed = await this.$dialog.confirm({
            title: '登录微信书',
            content: '登录后才能点赞哦，是否前往登录',
            okTitle: '前往登录',
            cancelTitle: '算了'
          })
          if (!confirmed) {
            return
          }
        }
        this.$router.push('/signin?auto=1&redirect=' + encodeURIComponent(url))
        return
      }

      const {id, bookType} = this.book
      const result = await this.$req.post(`/api/booklike/${bookType}/${id}/add_likes`)
      if (!result) {
        return this.$alert.warn('啊哈，不能重复点赞哦')
      }

      this.supporters.status = 0

      this.bookMeta.totalLikes++
      this.bookMeta.hasLiked = true
      this.$alert.success('感谢您的点赞哦')

      // this.makeBookAdActive = true
    },
    visitBook() {
      const {id, bookType} = this.book
      return this.$req.post(`/api/booklike/${bookType}/${id}/add_views`, {silent: true})
        .then(() => {
          this.bookMeta.views++
        })
    },
    makeBook() {
      if (this.book.uid === '1865901305') {
        // 邓伦的微博书
        this.$router.push('/books/' + this.$route.params.bookId + '/buy')
        return
      }
      this.makeBookAdActive = true
    }
  }
}
</script>

<style lang="scss">
body[data-page="bookShare"] {
  main {
    min-height: 0;
    padding-bottom: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
.book-share {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  padding-bottom: 64px;
  max-width: 100%;

  @include media-breakpoint-up(sm) {
    padding-top: $navbar-height;
  }

  header {
    text-align: right;
    padding: .75rem;
    background-color: #fff;
    border-bottom: 1px solid $hr-border-color;
  }

  section {
    width: 100%;
    height: 100%;

    .book-previewer {
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    &.no-share {
      text-align: center;

      img {
        width: 50%;
        max-width: 320px;
        margin-top: 2rem;
      }
    }
  }

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid $hr-border-color;
    padding: .75rem 1rem;
    background-color: #fff;

    .row {
      flex-wrap: nowrap;
    }

    .footer-inner {
      max-width: 560px;
      margin-left: auto;
      margin-right: auto;
      white-space: nowrap;
    }
  }
}
</style>
