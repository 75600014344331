<template>
  <div class="book-previewer">
    <div class="preview-zone" :class="[book.type, 'step-' + step]">
      <b-link @click.exact="navigate(page - step)" @click.shift="navigate(0)" class="prev"
              v-if="!isStart">
        <fa icon="chevron-left"/>
      </b-link>
      <b-link @click.exact="navigate(page + step)" @click.shift="navigate(pages.length)"
              class="next"
              v-if="!isEnd">
        <fa icon="chevron-right"/>
      </b-link>

      <div class="preview-inner">
        <div class="pages" :style="pagesStyle">
          <div class="half">
            <component class="preview-page" :is="leftPage.component" :style="halfStyle"
                       v-bind="leftPage.bind" :key="'page' + page"
                       v-if="leftPage"/>
            <loading v-else/>
            <img src="../assets/images/swiper-tip.png" class="swipe-tip" v-if="page < 1">
          </div>
          <div class="half" v-if="step === 2 && !isStart && !isEnd">
            <component class="preview-page" :is="rightPage.component" :style="halfStyle"
                       v-bind="rightPage.bind" :key="'page' + (page + 1)"
                       v-if="rightPage"/>
            <loading v-else/>
          </div>
        </div>
      </div>
    </div>

    <div class="pager">
      <b-btn size="sm" variant="link" class="text-body" @click="goto">
        <span v-if="page === 0">封面</span>
        <span v-else-if="step === 2 && page === 2">1</span>
        <span v-else-if="step === 2">{{page - 2}} ~ {{page - 1}}</span>
        <span v-else>{{page}}</span>
        /
        {{maxPage}}
      </b-btn>
    </div>
  </div>
</template>

<script>
import Hammer from 'hammerjs'
import { chain, cloneDeep, forEach } from 'lodash'

export default {
  name: 'bookPreviewer',
  props: {
    book: Object
  },
  components: {
    CoverPage: require('./Book/CoverPage').default,
    InsertPage: require('./Book/InsertPage').default,
    ContentPage: require('./Book/ContentPage').default,
    TitlePage: require('./Book/TitlePage').default,
    CopyrightPage: require('./Book/CopyrightPage').default,
    PrefacePage: require('./Book/PrefacePage').default,
    CatalogPage: require('./Book/CatalogPage').default,
    AcknowledgementPage: require('./Book/AcknowledgementPage').default,
    EmptyPage: require('./Book/EmptyPage').default,
    EndPage: require('./Book/EndPage').default
  },
  data() {
    return {
      months: [],
      loading: false,
      page: 0,
      pageWidth: this.book.type.includes('A5') ? 560 : 794,
      pageHeight: this.book.type.includes('A5') ? 790 : 1078,
      scale: 1,
      activePage: null,
      nextPage: null,
      innerPages: Array(this.book.pages)
    }
  },
  created() {
    document.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.onKeyUp)
    this.hammer.destroy()
  },
  watch: {
    '$store.state.winSize'() {
      const containerW = this.$el.querySelector('.preview-zone').clientWidth
      this.scale = containerW / this.pageWidth
    }
  },
  computed: {
    initialPages() {
      const isDouble = this.step === 2
      const pages = []
      pages.push({
        component: 'cover',
        bind: {style: {'box-shadow': 'none'}}
      })
      if (isDouble) {
        pages.push({component: 'div'})
        pages.push({component: 'div'})
      }
      pages.push({component: 'title-page'})
      pages.push({component: 'copyright-page', bind: {book: this.book}})

      const {catalogPages, acknowledgement, preface} = this.book

      if (acknowledgement) {
        pages.push({component: 'acknowledgement-page'})
        if (isDouble) {
          pages.push({component: 'div'})
        }
      }
      if (preface) {
        pages.push({component: 'preface-page'})
        if (isDouble) {
          pages.push({component: 'div'})
        }
      }
      if (catalogPages?.length) {
        for (const page of catalogPages) {
          pages.push({
            component: 'catalogPage',
            bind: {
              isFirst: catalogPages.indexOf(page) === 0,
              book: this.book,
              content: page.content
            }
          })
        }
        if (catalogPages.length % 2 && isDouble) {
          pages.push({component: 'div'})
        }
      }
      return pages
    },
    hasInsert() {
      if (this.book.bookType === 'blogbook') {
        return false
      }
      return this.book?.style?.insertPage !== 'blank'
    },
    isStart() {
      return this.page <= 0
    },
    isEnd() {
      return this.page >= this.pages.length - this.step
    },
    maxPage() {
      return this.pages.length - (this.step === 2 ? 3 : 1)
    },
    monthPages() {
      const results = {}
      if (Array.isArray(this.months)) {
        this.months.forEach(i => {
          results[i.pageNo] = i.id
        })
        return results
      }
      forEach(this.months, (val, year) => {
        if (!val) {
          return
        }
        const {months, pagesOffset} = val
        months.forEach((month, i) => {
          results[pagesOffset[i]] = year + ('0' + month).slice(-2)
        })
      })
      return results
    },
    step() {
      return (this.$store.state.innerWidth - 64 > this.pageWidth * this.scale * 2) ? 2 : 1
    },
    pagesStyle() {
      const cWidth = this.$store.state.innerWidth - 42
      const cHeight = this.$store.state.innerHeight - 56 - 64 - 48
      let pWidth = this.pageWidth * this.step
      const pHeight = this.pageHeight
      const scale = Math.min(Math.min(
        cWidth * this.step / this.pageWidth,
        cHeight / this.pageHeight
      ), 1)
      if (this.isStart || this.isEnd) {
        pWidth = this.pageWidth
      }
      return {
        width: pWidth * scale + 'px',
        height: pHeight * scale + 'px'
      }
    },
    halfStyle() {
      if (this.isStart || this.isEnd) {
        return null
      }
      const style = {}
      const cWidth = this.$store.state.innerWidth - 42
      const cHeight = this.$store.state.innerHeight - 56 - 64 - 48
      const scale = Math.min(cWidth * this.step / this.pageWidth, cHeight / this.pageHeight)
      if (scale < 1) {
        style.transform = 'scale(' + scale + ')'
        style.transformOrigin = '0 0'
      }
      return style
    },
    leftPage() {
      const page = this.pages[this.page]
      if (page) {
        page.bind = Object.assign({book: this.book}, page.bind)
      }
      return page
    },
    rightPage() {
      if (this.step === 1 || this.isStart || this.isEnd) {
        return null
      }
      const page = this.pages[this.page + 1]
      if (page) {
        page.bind = Object.assign({book: this.book}, page.bind)
      }
      return page
    },
    pages() {
      const pages = [
        ...this.initialPages,
        ...this.innerPages
      ]
      if (this.step === 2 && this.innerPages.length % 2 === 0) {
        pages.push({component: 'div'})
      }
      pages.push({component: 'end-page'})
      if (this.step === 2 && this.innerPages.length % 2 === 0) {
        pages.push({component: 'div'})
      }
      return pages
    }
  },
  mounted() {
    this.initHammer()
    if (this.book.auth) {
      this.initMonths()
    } else {
      this.innerPages = [
        {
          component: 'empty-page'
        }
      ]
    }
  },
  methods: {
    onLoad() {
      if (this.hasInsert) {
        // 指定了插页的情况，先将所有插页放入innerPages
        forEach(this.monthPages, (month, startPage) => {
          startPage = Number(startPage)
          this.innerPages[startPage - 1] = {
            component: 'insert-page',
            bind: {date: month}
          }
        })
      }
    },
    async initMonths() {
      this.months = await this.$ajax.fetchMonths({bookId: this.$route.params.bookId, raw: true})
      this.onLoad()
    },
    initHammer() {
      const hammer = new Hammer(this.$el)
      this.hammer = hammer
      hammer.on('swipe', (e) => {
        if (e.deltaX < 0 && e.distance > 50) {
          this.navigate(this.page + 1)
        }
        if (e.deltaX > 0 && e.distance > 50) {
          this.navigate(this.page - 1)
        }
      })
    },
    onKeyUp(e) {
      const offset = (e.shiftKey ? 10 : 1) * this.step
      if (e.which === 37 || e.which === 33) {
        // 上一页
        // <-
        // PageUp
        this.navigate(this.page - offset)
      }
      if (e.which === 39 || e.which === 34) {
        // 下一页
        // ->
        // PageDown
        this.navigate(this.page + offset)
      }
      if (e.which === 36) {
        // Home
        // 第一页
        this.navigate(0)
      }
      if (e.which === 35) {
        // End
        // 最后一页
        this.navigate(this.pages.length)
      }
    },
    goto() {
      const page = prompt('请输入您要前往的页面 0（封面）~' + this.pages.length)
      if (page === null || page === undefined) {
        return
      }
      this.navigate(Number(page))
    },
    navigate(page) {
      page = Math.max(0, Math.min(this.pages.length - 1, page))
      page = page - page % this.step
      if (!this.pages[page]) {
        this.updatePages(page)
      }
      if (!this.pages[page + 1]) {
        this.updatePages(page + 1)
      }
      if (!this.pages[page + 2]) {
        this.updatePages(page + 2)
      }
      if (!this.pages[page - 1]) {
        this.updatePages(page - 1)
      }
      if (!this.pages[page - 2]) {
        this.updatePages(page - 2)
      }
      this.page = page
    },
    async updatePages(page) {
      if (this.pages[page]) {
        return
      }

      // 首先将页码转换为到内页的 index
      const index = page - this.initialPages.length
      // 找到当前页数所在的月份的起始页数
      let startPage = chain(this.monthPages)
        .keys()
        .map(Number)
        .findLast(i => i <= index + 1)
        .value()

      const date = this.monthPages[startPage]
      if (!date) {
        return
      }

      if (!this.hasInsert) {
        startPage--
      }

      const results = await this.$ajax.fetchBookPages({
        bookId: this.$route.params.bookId,
        month: date
      })

      results.forEach((page, i) => {
        this.innerPages[startPage + i] = {
          component: 'content-page',
          bind: {
            page,
            date,
            load: true // 直接加载页面内容
          }
        }
      })

      this.innerPages = cloneDeep(this.innerPages).map((p, index) => {
        if (!p) {
          return null
        }
        if (p.bind?.page) {
          p.bind.page.pageNo = index + 1
        }
        return p
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.book-previewer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 10px 1.5rem 2rem;

  .prev, .next {
    position: absolute;
    height: 4em;
    width: 1em;
    top: 0;
    bottom: 0;
    margin: auto 0;
    z-index: 5;
    font-size: 4rem;
    text-align: center;
    line-height: 4;
    padding: 0;
    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }

  .prev {
    left: -1em;
  }

  .next {
    right: -1em;
  }

  .swipe-tip {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 20%;
    pointer-events: none;
    opacity: .9;
  }

  .pager {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    @include media-breakpoint-down(sm) {
      font-size: 1.5em;
    }
  }

  .preview-zone {
    position: relative;
    max-width: 100%;
    max-height: 100%;

    .pages {
      box-shadow: $box-shadow;
      display: flex;
      flex-direction: row;
    }

    &.step-1 {
      .half:before {
        display: none;
      }
    }

    .half {
      position: relative;
      width: 100%;
      overflow: hidden;
      background-color: #fff;
      pointer-events: none;

      &:before {
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        bottom: 0;
        width: 30px;
        background-image: linear-gradient(270deg, #c7c7c7, transparent);
        opacity: .7;
        z-index: 5;
        pointer-events: none;
      }

      & + .half {
        &:before {
          position: absolute;
          content: '';
          right: auto;
          left: 0;
          background-image: linear-gradient(90deg, #c7c7c7, transparent);
        }
      }

      &:after {
        display: block;
        content: '';
        padding-bottom: 141%;
        pointer-events: none;
      }

      &:only-child:before {
        display: none;
      }

      .loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 2em;
      }
    }

    &[class*="A4"] .half {
      &:after {
        padding-bottom: 135.8%;
      }
    }

    .preview-page {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
    }

    .cover {
      &:after {
        content: '';
        display: block;
        position: absolute;
        background-image: url(../assets/images/album/album-shadow.png);
        background-repeat: repeat-y;
        background-size: 100%;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>
