<template>
  <div class="empty-page page">
    <div class="mb-5">
      <img src="https://img.xinshu.me/upload/eda440e0b2ce48dbbeefdc48fd86d598">
    </div>
    <h5 class="my-4">作者未公开内容，暂时无法查看</h5>
  </div>
</template>

<script>
export default {
  name: 'emptyPage'
}
</script>

<style scoped lang="scss">
  .empty-page {
    padding: 10%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $text-muted;
    h5 {
      font-size: 1.5em;
    }
  }
</style>
