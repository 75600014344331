<template>
  <div class="end-page">
    <square src="https://img.xinshu.me/upload/fab544122fa72fe9b4b0746cf9f2a99b!800"
            height="a5">
      <div class="barcode">
        <img :src="barcode" alt="">
      </div>
    </square>
  </div>
</template>

<script>
export default {
  name: 'end-page',
  props: ['book'],
  computed: {
    barcode() {
      return 'https://canvas.xinshu.me/barcode/' + this.book.id + '?displayValue=1'
    }
  }
}
</script>

<style lang="scss" scoped>
.barcode {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10%;
  max-width: 15%;
  margin: auto;
  height: 5%;
  text-align: center;
  line-height: 1;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>
