<template>
  <div class="book-share">
    <template v-if="loading">
      <b-card>
        <book-item :value="{}">
          <ske type="cover" slot="cover"/>
          <ske type="text" slot="title" width="10em"/>
          <template slot="info">
            <ske type="text" width="6em" block/>
            <ske type="text" width="6em" block/>
          </template>
        </book-item>
      </b-card>

      <b-card>
        <div>
          <ske type="text" width="10em"/>
        </div>
        <div>
          <ske type="text" width="8em"/>
        </div>
      </b-card>
    </template>

    <template v-else>
      <b-card>
        <book-item :value="book" class="mb-3" :to="bookLink" :disabled="!canEdit">
          <template slot="info">
            <div class="text-overflow">
              <b-badge variant="muted">{{book.typesetName}}</b-badge>
              <b-badge variant="muted">{{book.pages || 0}}页</b-badge>
            </div>
            <div class="small text-overflow">
              <fa icon="clock" fw/>
              <datetime :value="book.fromDate" format="YYYY.MM"/>
              -
              <datetime :value="book.toDate" format="YYYY.MM"/>
            </div>
          </template>
          <template slot="action">
            <b-btn :to="'/book-share/' + book.bookId">查看好友预览效果</b-btn>
          </template>
        </book-item>

        <b-row v-if="canEdit">
          <b-col>
            <b-btn block @click="shareBook" variant="primary">
              <fa icon="share"/>
              分享求赞
            </b-btn>
          </b-col>
          <b-col v-if="!isBlogbook">
            <b-btn block @click="setPublic(!book.shared)">
              <template v-if="book.shared">
                <fa icon="lock" fw/>
                取消分享
              </template>
              <template v-else>
                <fa icon="eye" fw/>
                设为公开
              </template>
            </b-btn>
          </b-col>
        </b-row>
      </b-card>

      <template v-if="!isBlogbook">
        <template v-if="canEdit">
        <b-card>
          <div>
            当前作品总共被阅读
            <fa icon="book-open"/>
            {{bookMeta.views || 0}} 次，收到
            <fa icon="thumbs-up"/>
            {{book.totalLikes || 0}} 赞
          </div>
          <div class="text-muted small" v-if="desc">
            <fa icon="info-circle"/>
            <span v-if="desc" v-html="desc"></span>
          </div>

          <div class="mt-3">以下是近期为您点赞的好友</div>
          <loading v-if="supporters.loading"/>
          <b-row v-else-if="supporters.length">
            <b-col cols="6" v-for="item in supporters" :key="item.openid">
              <b-row align-v="center" class="mt-3">
                <b-col cols="auto" class="pr-0">
                  <avatar :src="item.headimgurl" bordered size="3em"/>
                </b-col>
                <b-col style="min-width: 0;">
                  <h6 class="text-overflow mb-0">{{item.nickname}}</h6>
                  <fa icon="thumbs-up"/>
                  <template v-if="item.totalLikeCount > 1">{{item.totalLikeCount}}</template>
                  <datetime class="text-muted" :value="item.latestLikeTime"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <empty class="mt-2" dark icon="users" v-else>近期暂无点赞好友哦</empty>
        </b-card>
      </template>
        <b-card v-else-if="!book.isAgentBook">
          <h4 class="mb-3">你也可以拥有一本这样的微信书</h4>
          <p>
            <square :src="poster" height="80%"/>
          </p>
          <b-btn block @click="popupActive = true">查看{{nickname}}的微信书</b-btn>
        </b-card>
      </template>

      <popup v-if="popupActive" size="800x845"
             src="https://img.xinshu.me/upload/b74f231b2d06419998c019d58303d9d8!800"/>

      <share-poster :book="book"
                    :shareBookId="$route.params.bookId"
                    :show.sync="posterActive"
                    v-if="canEdit">
        <template slot="desc">
          长按保存下方海报，分享给好友
          <div v-if="isWechat">
            也可点击右上角
            <fa icon="ellipsis-h"/>
            分享
          </div>
        </template>

        <div class="text-pre-wrap mt-2" v-html="rule" slot="append"></div>
      </share-poster>
    </template>
  </div>
</template>

<script>
import route from '@/mixins/route-data'

import { configShare } from '@/modules/wechat'
import copyText from 'clipboard-copy'

export default {
  name: 'bookShare',
  title: '分享作品',
  components: {
    BookItem: require('./Book/BookItem').default,
    SharePoster: require('@/components/SharePoster').default
  },
  mixins: [
    require('@/mixins/book-utils').default,
    route('book'),
    route('bookMeta')
  ],
  data() {
    return {
      rule: '',
      desc: '',
      supporters: [],
      posterActive: false,
      popupActive: false,
      submitting: false
    }
  },
  computed: {
    poster() {
      return 'https://canvas.xinshu.me/generate/share-1' + this.serialize({
        pic: this.avatar.replace('/132', '/0'),
        title: this.nickname + '的微信书',
        author: this.nickname
      })
    },
    canEdit() {
      return this.book.auth === 'edit'
    },
    avatar() {
      return this.user?.avatar || ''
    },
    nickname() {
      return this.user?.nickname || '我'
    },
    isBlogbook() {
      return this.book.bookType === 'blogbook'
    },
    bookLink() {
      const bookId = this.$route.params.bookId
      if (this.canEdit) {
        return '/books/' + bookId
      }
      return '/books/' + bookId + '/share'
    }
  },
  created() {
    this.$ajax.fetchPromotion().then(data => {
      this.rule = data.share?.rule
      this.desc = data.share?.desc
    })
  },
  methods: {
    onLoad() {
      const shareContents = {
        wxbook: '我的朋友圈内容做成书啦，进来帮我点赞吧！',
        wbbook: '我的微博内容做成书啦，进来帮我点赞吧！',
        diarybook: '我的回忆做成书啦，进来帮我点赞吧！'
      }

      this.$setTitle('分享' + this.book.title)
      configShare({
        title: document.title,
        timelineTitle: shareContents[this.book.bookType],
        link: 'https://weixinshu.com/book-share/' + this.$route.params.bookId,
        desc: shareContents[this.book.bookType] || shareContents.diarybook,
        imgUrl: this.bookCover
      })

      if (this.canEdit && !this.isBlogbook) {
        this.supporters.loading = true
        this.$ajax.fetchSupporters(this.$route.params).then(results => {
          this.supporters = results
        })
      } else {
        if (this.bookMeta.isSharing) {
          this.visitBook()
        }
      }
    },
    async setPublic(value) {
      if (!value) {
        const confirmed = await this.$dialog.confirm({
          title: '取消分享',
          content: '取消分享后作品会设为私密，其他人将无法预览内容及点赞'
        })
        if (!confirmed) {
          return
        }
      } else {
        const goShare = await this.$dialog.confirm({
          title: '分享作品',
          content: '您需要先公开作品，分享后其他人才能查看内容哦，您可在目录菜单或分享页面中取消分享。',
          okTitle: '公开并分享',
          cancelTitle: '算了'
        })
        if (!goShare) {
          return
        }
      }

      const book = this.book

      await this.$req.post('/api/library/edit_share_book', {
        bookId: book.id,
        bookType: book.bookType,
        shared: !!value
      })

      book.shared = !!value

      if (value) {
        this.posterActive = true
      } else {
        this.$alert.success('已将作品设置为私密')
      }
    },
    async shareBook() {
      if (this.isBlogbook) {
        const link = 'https://weixinshu.com/book-share/' + this.book.bookId
        await copyText(link)
        this.$dialog.confirm({
          title: '分享作品',
          content: '链接已复制，请发送给好友',
          okTitle: '好的',
          okOnly: true
        })
        return
      }
      if (this.book.shared) {
        this.posterActive = true
      } else {
        this.setPublic(true)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.book-share {
  max-width: 640px;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200px;
  padding-top: 2rem;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
  @include bgi('https://img.xinshu.me/upload/6d2d9dc42e7b4f1292fbcaba00c9a0e3!1600');

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 16em;
  }
}
</style>
